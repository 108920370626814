import React, { useState } from "react";
import "../style/footer/footer.css";
import logo from "../assets/landing_page/logo.png";
import arrow from "../assets/newsletter-arrow.png";
import instagram from "../assets/instagram.png";
import facebook from "../assets/facebook.png";
import linkedin from "../assets/linkedin.png";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = ({ setOverlayActive }) => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling behavior
    });
  };

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      // Make HTTP POST request to the API endpoint with JSON body
      const response = await axios.post(
        "https://benchmark.butterflylearnings.com/clients/benchmark_newsletter/",
        formData
      );

      toast.success("Subscription Successful");

      setFormData((prevData) => ({
        ...prevData,
        email: "",
      }));

      // console.log("Response:", response.data);
      // Handle success response, e.g., show success message, redirect, etc.
    } catch (error) {
      console.error("Error:", error);
      // Handle error response, e.g., show error message, retry, etc.
    }
  };

  const handleClick = () => {
    // Call the function passed down as prop to update menuStatus
    setOverlayActive((prevStatus) => !prevStatus);
  };

  return (
    <>
      <div className="footer-container">
        <div className="footer-column">
          <div className="footer-column-heading">Product</div>

          <div
            className="footer-column-subheading"
            onClick={() => navigate(`/features`)}
          >
            Features
          </div>

          <div
            className="footer-column-subheading"
            onClick={() => navigate(`/pricing`)}
          >
            Pricing
          </div>

          <div className="footer-column-subheading">Demo</div>
        </div>

        <div className="footer-column">
          <div className="footer-column-heading">Explore</div>

          <div className="footer-column-subheading">Our Blog</div>
        </div>

        <div className="footer-column">
          <div className="footer-column-heading">Company</div>

          <div
            className="footer-column-subheading"
            onClick={() => navigate(`/about-us`)}
          >
            About Us
          </div>

          <div className="footer-column-subheading" onClick={handleClick}>
            Contact Us
          </div>
        </div>

        <div className="newletter-div">
          <div className="newletter-heading">Subscribe to our platform</div>

          <div class="newsletter-text-field-box">
            <input
              type="text"
              class="newsletter-text-field"
              placeholder="Email Address"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <div class="newsletter-text-field-button" onClick={handleSubmit}>
              <img src={arrow} alt="" width={15} height={15} />
            </div>
          </div>

          <div className="newletter-subheading">
            Be the first to find out about what we've released, upcoming
            webinars, company news, and interesting things in the world of
            behavioral health.
          </div>
        </div>
      </div>

      <div className="mobile-footer-container">
        <div className="mobile-footer-link-row">
          <div className="footer-column">
            <div className="footer-column-heading">Product</div>

            <div
              className="footer-column-subheading"
              onClick={() => navigate(`/features`)}
            >
              Features
            </div>

            <div
              className="footer-column-subheading"
              onClick={() => navigate(`/pricing`)}
            >
              Pricing
            </div>

            <div className="footer-column-subheading">Demo</div>
          </div>

          <div className="footer-column">
            <div className="footer-column-heading">Explore</div>

            <div className="footer-column-subheading">Our Blog</div>
          </div>
        </div>

        <div className="mobile-footer-link-row" style={{ paddingTop: 50 }}>
          <div className="footer-column">
            <div className="footer-column-heading">Company</div>

            <div
              className="footer-column-subheading"
              onClick={() => navigate(`/about-us`)}
            >
              About Us
            </div>

            <div className="footer-column-subheading" onClick={handleClick}>
              Contact Us
            </div>
          </div>
        </div>

        <div className="newletter-div">
          <div className="newletter-heading">Subscribe to our platform</div>

          <div class="newsletter-text-field-box">
            <input
              type="text"
              class="newsletter-text-field"
              placeholder="Email Address"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <div class="newsletter-text-field-button" onClick={handleSubmit}>
              <img src={arrow} alt="" width={15} height={15} />
            </div>
          </div>

          <div className="newletter-subheading">
            Be the first to find out about what we've released, upcoming
            webinars, company news, and interesting things in the world of
            behavioral health.
          </div>
        </div>
      </div>

      <div className="footer-divider"></div>

      <div className="footer-last-row">
        <img
          src={logo}
          alt=""
          className="footer-logo"
          onClick={() => navigate(`/`)}
          style={{ cursor: "pointer" }}
        />

        <div className="footer-terms-row">
          <div className="footer-terms-text" onClick={() => navigate(`/terms`)}>
            Terms
          </div>

          <div
            className="footer-terms-text"
            onClick={() => navigate(`/privacy`)}
          >
            Privacy
          </div>
        </div>

        {/* <div className="footer-social-row">

          <img src={instagram} alt="" width={35} height={35}/>

          <img src={linkedin} alt="" width={35} height={35}/>

          <img src={facebook} alt="" width={35} height={35}/>

        </div> */}
      </div>
    </>
  );
};

export default Footer;
