import React, { useState } from "react";
import "../../style/features_page/features_page.css";
import appStore from "../../assets/features_page/app-store.png";
import playStore from "../../assets/features_page/play-store.png";
import downloadScreenshot from "../../assets/features_page/download-screenshot.png";
import greyTick from "../../assets/features_page/grey_tick.png";
import { toast } from "react-toastify";

const DownloadSection = () => {
  return (
    <>
      <div className="download-div">
        <div className="download-img-container">
          <img
            src={downloadScreenshot}
            alt=""
            className="download-screenshot"
          />
        </div>

        <div className="mob-download-img-container">
          <img
            src={downloadScreenshot}
            alt=""
            className="download-screenshot"
          />
        </div>

        <div style={{ gap: 40, display: "flex", flexDirection: "column" }}>
          <div className="download-div-heading">
            Our Mobile APP is a tap away!
          </div>

          <div className="download-button-row">
            <img
              src={appStore}
              alt=""
              className="download-button"
              onClick={() => toast.success("Coming Soon!")}
            />
            <img
              src={playStore}
              alt=""
              className="download-button"
              onClick={() => toast.success("Coming Soon!")}
            />
          </div>

          <div className="download-button-row-1">
            <img src={greyTick} className="mob-download-grey-tick" />
            24X7 Customer Assistance
          </div>

          <div className="download-button-row-1">
            <img src={greyTick} className="mob-download-grey-tick" />
            Dedicated Account Manager
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadSection;
