import React, { useState } from "react";
import "../../style/new_landing/new_landing_style.css";
import banner from "../../assets/new_landing_page/home.png";
import mobileBanner from "../../assets/new_landing_page/mobile_home.png";
import landingInfo from "../../assets/new_landing_page/new_landing_mobile.png";
import landingInfoMobile from "../../assets/new_landing_page/new_landing_mobile_mobile.png";
import smiley from "../../assets/new_landing_page/smiley.png";
import close from "../../assets/new_landing_page/close_button.png";
import NewLandingPageHeaderTitle from "./landing_header/landing_header_title";
import NewLandingPageHeaderSubTitle from "./landing_header/landing_header_subtitle";
import NewLandingBookDemo from "./landing_header/landing_book_demo";
import linkedIn from "../../assets/new_landing_page/linkedin.jpg";

const NewLandingPageHeader = ({ setOverlayActive }) => {
  const [isPopUpVisible, setPopUpVisible] = useState(true); // State to control pop-up visibility

  // Function to hide the pop-up
  const handleClosePopUp = () => {
    setPopUpVisible(false);
  };

  const handleJoinNow = () => {
    setPopUpVisible(false);
    setOverlayActive((prevStatus) => !prevStatus);
  };

  return (
    <>
      <div className="new-landing-page-header">
        <div className="new-landing-banner-img"></div>
        <div className="new-landing-header-content">
          <NewLandingPageHeaderTitle />
          <img src={smiley} alt="Banner" className="new-landing-smiley-img" />
          <NewLandingPageHeaderSubTitle />
          <NewLandingBookDemo setOverlayActive={setOverlayActive} />
          <img
            src={landingInfo}
            alt="Banner"
            className="new-landing-info-img"
          />
          {isPopUpVisible && (
            <div className="new-landing-header-pop-up-linkedin">
              <div className="new-landing-pop-up-image-wrapper">
                <div className="image-overlay-container">
                  <img
                    src={linkedIn}
                    alt="Logo"
                    className="new-landing-pop-up-image"
                  />
                  <button
                    className="overlay-button"
                    onClick={handleJoinNow}
                    aria-label="Transparent Action Button"
                  />
                </div>
                <img
                  src={close}
                  alt="Close"
                  width={18}
                  height={18}
                  onClick={handleClosePopUp}
                  className="new-landing-header-pop-up-close-btn"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="new-landing-page-header-mobile">
        <div className="new-landing-banner-img"></div>
        <div className="new-landing-header-content">
          <NewLandingPageHeaderTitle />
          <NewLandingPageHeaderSubTitle />
          <NewLandingBookDemo setOverlayActive={setOverlayActive} />
          <img
            src={landingInfoMobile}
            alt="Banner"
            className="new-landing-info-img"
          />
          {isPopUpVisible && (
            <div className="new-landing-header-pop-up-linkedin">
              <div className="new-landing-pop-up-image-wrapper">
                <img
                  src={linkedIn}
                  alt="Logo"
                  className="new-landing-pop-up-image"
                />
                <img
                  src={close}
                  alt="Close"
                  width={18}
                  height={18}
                  onClick={handleClosePopUp}
                  className="new-landing-header-pop-up-close-btn"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NewLandingPageHeader;
